<template>
  <div class="add_teacher">
    <el-card class="box-card">
      <el-form
        ref="addFromRef"
        :model="addFrom"
        :rules="addFromRules"
        label-width="110px"
      >
        <el-form-item label="标题:" prop="title">
          <el-input v-model="addFrom.title" style="width: 300px"></el-input>
        </el-form-item>

        <el-form-item label="展示图:" prop="v">
          <send-image
            type="one"
            :images="addFrom.showImg"
            @addImageStr="(e) => addImageStr(e, 1)"
          ></send-image>
          <div class="title_block">图片不能大于500k，建议尺寸为345*172</div>
        </el-form-item>
        <el-form-item label="活动ID:" prop="details">
          <el-input v-model="addFrom.details" type="number"  style="width: 300px"></el-input>
          <!-- <VueUeditorWrap
            v-model="addFrom.details"
            :config="myConfig"
            ref="myTextEditor"
          ></VueUeditorWrap> -->
        </el-form-item>
        <el-form-item>
          <el-button type="success" @click="submitForm">保存</el-button>
          <el-button type="primary" @click="goBack">返回</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import VueUeditorWrap from "vue-ueditor-wrap";
import { fingerpostListAdd, fingerpostListDetails } from "../../../api/fingerpost.js";

import sendImage from "@/components/sendImage.vue";
// import { teacherAdd } from "../../api/class.js";
export default {
  components: { VueUeditorWrap, sendImage },
  data() {
    return {
      myConfig: {
        toolbars: [
          [
            "simpleupload", //单图上传
            "fullscreen",
            "source",
            "|",
            "undo",
            "redo",
            "|",
            "bold",
            "italic",
            "underline",
            "fontborder",
            "strikethrough",
            "superscript",
            "subscript",
            "removeformat",
            "formatmatch",
            "autotypeset",
            "blockquote",
            "pasteplain",
            "|",
            "forecolor",
            "backcolor",
            "insertorderedlist",
            "insertunorderedlist",
            "selectall",
            "|",
            "rowspacingtop",
            "rowspacingbottom",
            "lineheight",
            "|",
            "customstyle",
            "paragraph",
            "fontfamily",
            "fontsize",
            "|",
            "directionalityltr",
            "directionalityrtl",
            "indent",
            "|",
            "justifyleft",
            "justifycenter",
            "justifyright",
            "justifyjustify",
            "|",
            "touppercase",
            "tolowercase",
            "|",
            "imagenone",
            "imageleft",
            "imageright",
            "imagecenter",
            "|",
            "emotion",
            "map",
            "insertcode",
            "background",
            "|",
            "horizontal",
            "date",
            "time",
            "spechars",
            "|",
            "inserttable",
            "deletetable",
            "insertparagraphbeforetable",
            "insertrow",
            "deleterow",
            "insertcol",
            "deletecol",
            "mergecells",
            "mergeright",
            "mergedown",
            "|",
            "print",
            "preview",
          ],
        ],
        autoHeightEnabled: false, // 编辑器不自动被内容撑高
        initialFrameHeight: 600, // 初始容器高度
        initialFrameWidth: "50%", // 初始容器宽度
        serverUrl: "/config",
        UEDITOR_HOME_URL: "/static/UEditor/",
        maximumWords: "100000000",
      },
      addFrom: {
        manualId: 0,
        showImg: "",
        title: "",
        details: "",
      },
      addFromRules: {
        title: [{ required: true, message: "请填写标题", trigger: "blur" }],
        showImg: [{ required: true, message: "请上传展示图", trigger: "blur" }],
        details: [{ required: true, message: "请填写ID", trigger: "blur" }],
      },
    };
  },
  created() {
    this.addFrom.manualId = this.$route.query.id;
    if (this.addFrom.manualId) {
      this.getDetails();
    }
  },
  methods: {
    getDetails() {
      fingerpostListDetails({ manualId: this.addFrom.manualId }).then((res) => {
        if (res.data.code == 0) {
          this.addFrom = res.data.data;
        }
      });
    },
    handleAvatarSuccess(res, file, type) {
      this.addFrom.headImgUrl = file.response.data[0].src;
    },
    submitForm() {
      this.$refs.addFromRef.validate(async (validate) => {
        if (!validate) return;
        if (this.isSubmit) return;
        this.isSubmit = true;
        const { data } = await fingerpostListAdd(this.addFrom);
        this.isSubmit = false;
        if (data.code == 0) {
          this.goBack();
          this.$message.success(data.msg);
        } else {
          this.$message.warning(data.msg);
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    addImageStr(e, type) {
      this.addFrom.showImg = e;
    },
  },
};
</script>

<style scoped>
.avatar-uploader-icon {
  border: 1px dashed #eee;
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 150px;
  height: 150px;
}
.box-card {
  padding-bottom: 50px;
}
</style>
